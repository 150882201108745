const english = {
  $groupMessage: "GROUP MESSAGES",
  $directMessage: "DIRECT MESSAGES",
  $about: "About",
  $members: "Members",
  $settings: "Settings",
  $addHost: "Add Host",
  $addAttendee: "Add Attendee",
  $addUser: "Add User",
  $groupName: "Group Name",
  $groupDescription: "Group Description",
  $createdBy: "Created by",
  $microphone: "Microphone",
  $speaker: "Speaker",
  $camera: "Camera",
  $callSound: "Call Sound",
  $chatSound: "Chat Sound",
  $videoCall: "Video call",
  $monitorCall: "Monitor",
  $downloadForMac: "Download Mac App",
  $preparingForDownload: "Preparing for Download",
  $launchWindowsApp: "Launch Windows App",
  $downloadWindowsApp: "Download Windows App",
  $description: "Description",
  $selectAttendeesIsMandatory: "Select Attendees is mandatory",
  $back: "Back",
  $createGroup: "Create Group",
  $selectedAttendee: "Select Attendees",
  $selectAdditionalHosts: "Select Additional Hosts",
  $pTagStandardGroup:
    "This is Standard Group. Name your group around a topic, and enter a description if needed. Add Individual Attendees and additional Hosts.",
  $createStandardGroup: "Create Standard Group",
  $createAGroup: "Create a Group",
  $standardGroup: "Standard Group",
  $reactToolTipLine1: "Create Chat Groups by manually",
  $reactToolTipLine2: "adding and removing users.",
  $secondToolTipLine1: "Create Chat Groups by allowing",
  $secondToolTipLine2: "the system to dynamically add",
  $secondToolTipLine3: "and remove users based on the",
  $secondToolTipLine4: "rules you establish.",
  $dynamicGroup: "Dynamic Group",
  $learnMore: "Learn More",
  $add: "Add",
  $editGroupName: "Edit Group Name",
  $editGroupDescription: "Edit Group Description",
  $save: "Save",
  $toolTipForGroupSetting:
    "You can only edit a managed group from c2perform.com",
  $addPeople: "Add People",
  $muteGroup: "Mute Group",
  $leaveThisGroup: "Leave this group",
  $deleteThisGroup: "Delete this group",
  $audioVideo: "Audio & Video",
  $ringtone: "Ringtone",
  $translation: "Translation",
  $scheduledCalls: "Scheduled Calls",
  $previousMeetings: "Previous Meetings",
  $saveSettings: "Save Settings",
  $language: "Language",
  $spanScheduleCall: "All of the scheduled calls are listed on the link below.",
  $clickToProceed: "Please click here to proceed.",
  $spanRecordingCall: "All of the call recordings are listed on the link below",
  $playSound: "Play Sound",
  $playing: "Playing",
  $invitePeopleToCall: "Invite People to this call",
  $logoutFromAllDevice: "Logout from all devices",
  $cancel: "Cancel",
  $delete: "Delete",
  $leave: "Leave",
  $kickOut: "Kick Out",
  $remove: "Remove",
  $accept: "Accept",
  $badWordAlert: "Bad Word Alert",
  $pTagBadWordMessage:
    "This message has not been sent because it contains bad words.",
  $deleteMsg: "Delete message",
  $pTagDeleteMessage:
    "Are you sure you want to delete this message? This cannot be undone.",
  $fileSizeMsg:
    "File does not support. Please upload a file whose size is not more than 5GB",
  $close: "Close",
  $acceptCall: "Accept Call",
  $endCall: "End Call",
  $waitingForHost: "Waiting for Host",
  $raiseHand: "Raise hand",
  $mic: "Mic",
  $whiteboard: "Whiteboard",
  $declineCall: "declined the call",
  $group: "Group",
  $groupCall: "Group Call",
  $meetingRecorded: "This meeting is being recorded",
  $pTagMeetingRecorded:
    "By continuing to be in the meeting, you are consenting to be recorded",
  $leaveMeeting: "Leave Meeting",
  $continue: "Continue",
  $faqList: "FAQ list",
  $accordionItemOne: "Accordion Item One",
  $loremIpsum: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                est laborum.`,
  $accordionItemTwo: "Accordion Item Two",
  $accordionItemThree: "Accordion Item Three",
  $accordionItemFour: "Accordion Item Four",
  $cannotPlaceCall: "You cannot place a call if you're already on another call",
  $anotherCall: "is on another Call",
  $userProfile: "User Profile",
  $pTagUserProfile:
    "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum",
  $message: "Message",
  $huddle: "Huddle",
  $pTagGroupAdd:
    "Groups are where your team communicates. They're best when organized around a topic - #marketing, for example.",
  $groupNameErrorMsg: "The group name field is required.",
  $callingYou: "is calling you",
  $pms: "Performance Management System | Powered by",
  $dashboardError:
    "You have be logged out of this session, please close this app or window and login again with your credentials",
  $dashboardError1:
    "There seems to be a problem with your account. Please copy the following message and send to your system support so that it can be corrected. (Error code:",
  $belowHeaderError:
    "There seems to be a problem with this account. Please contact your administrator.",
  $desktopAlert: "Desktop Alerts",
  $desktopAlertMsg:
    "Desktop notification unread counts may sometimes get stuck or not match with the actual counts, if this happens,",
  $clearCount: "click here",
  $desktopAlertMsg1: "to clear desktop app icon counts.",
  $joinCall: "Join this call",
  $box: "Box",
  $polygon: "Polygon",
  $ellipse: "Ellipse",
  $arrow: "Arrow",
  $solidLine: "Solid Line",
  $freeHand: "Free Hand",
  $text: "Text",
  $image: "Image",
  $eraser: "Eraser",
  $select: "Select",
  $triangle: "Triangle",
  $ra_triangle: "Right Angle Triangle",
  $colorPicker: "Select Color",
  $clear: "Clear",
  $joinScheduleCall: "JOIN ONGOING CALL",
  $scheduledCallTitle: "Ongoing Call List",
  $join: "Join",
  $startScheduleCall: "START SCHEDULE CALL",
  $scheduledCallTitleForAdmin: "Schedule Call List",
  $start: "Start",
  $virtualBackground: "Virtual Background",
  $fullBlurBackground: "Full Blur Background",
  $partialBlurBackground: "Partial Blur Background",
  $GroupCallMaxLimit: "Group call is disabled over 75 attendees",
  $muteAll: "Mute All",
  $endMeetingForAll: "End Meeting for All",
  $endMeetingMsg: "The host has ended the meeting.",
  $hostRequiredMsg:
    "The meeting will start when a host arrives. Please stand by.",
  $exit: "Exit",
  $cannotMonitorUser: "You cannot monitor because user is on another call",
  $cannotMonitor: "You cannot monitor if you're already on another call",
};

export default english;
