/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, memo, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";

import GroupAdd from "../Groups/GroupAdd";
import { getAuthSession, notifyDesktopApp } from "../Helpers/common";
import OutBoundCall from "../models/OutBound";
import InBoundCall from "../models/InBoundCall";
import UserOnCall from "../models/UserOnCall";
import { setLoaderActiveData } from "../redux/chatSlice";
import { setCallRoom } from "../redux/callSlice";
import {
  getUserOrGroupListWithoutSearch,
  openUserChat,
  sendNotificationHandler,
} from "../Helpers/chatCommon";
import { GroupOutBoundCall } from "../Helpers/CallCommon";
import { globalStrings } from "../util/translation/languages";

const SidebarGroupMessage = (props) => {
  const dispatch = useDispatch();
  const unreadChatMessage = useSelector(
    (state) => state.chat.unreadChatMessage,
  );
  const sessionThemeData = useSelector(
    (state) => state.authUserDetail.sessionThemeData,
  );
  const authSession = useSelector(
    (state) => state.authUserDetail.authSession,
  )?.data;

  const allAttendeeList = useSelector((state) => state.call.allAttendeeList);

  const outBoundCallPopUpShow = useSelector(
    (state) => state.call.outBoundCallPopUpShow,
  );

  const inBoundCallPopUpShow = useSelector(
    (state) => state.call.inBoundCallPopUpShow,
  );

  const callDetail = useSelector((state) => state.call.callDetail);

  const callRoom = useSelector((state) => state.call.callRoom);
  const room = callRoom;

  const sidebarActiveData = useSelector(
    (state) => state.chat.sidebarActiveData,
  );
  const attendeeList = useSelector((state) => state.chat.attendeeList);
  const loaderActiveData = useSelector((state) => state.chat.loaderActiveData);
  const searchAttendeeData = useSelector(
    (state) => state.chat.searchAttendeeData,
  );
  const authSessionUser = useSelector(
    (state) => state.authUserDetail.authSessionUser,
  );
  const callActiveLoader = useSelector((state) => state.call.callActiveLoader);

  const monitorActiveLoader = useSelector(
    (state) => state.call.monitorActiveLoader,
  );
  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  const { loaderAttendeeGroup: loader, groupList } = useSelector(
    (state) => state.chat,
  );

  const { groupTypingData, notificationData } = props;
  const [show, setShow] = useState(false);
  const { user_unique_key: senderID } = getAuthSession();
  const [showGroup, setShowGroup] = useState(false);
  // const [finalGroupList, setFinalGroupList] = useState([]);
  const [isExpand, setIsExpand] = useState(true);
  const [chooseGroupTypeShow, setChooseGroupTypeShow] = useState(false);
  // console.log("finalGroupList", finalGroupList);
  const [callIconLoader, setCallIconLoader] = useState("");

  useEffect(() => {
    let check = allAttendeeList.find((list) => list?.user_id == senderID);
    if (check?.role !== "Agent") {
      setShowGroup(true);
    } else {
      setShowGroup(false);
    }
  }, [allAttendeeList]);

  useEffect(() => {
    if (unreadChatMessage.length > 0) {
      let totalCount = 0;
      unreadChatMessage.forEach((item) => {
        totalCount = totalCount + item.count;
      });
      notifyDesktopApp("", totalCount, "", "unread-message-count", "");
    }
  }, [unreadChatMessage]);

  // useEffect(() => {
  //   const list = getUserOrGroupListWithSearch("group");
  //   setFinalGroupList(list);
  // }, [groupList, searchAttendeeData, unreadChatMessage]);

  const groupListingData = useMemo(() => {
    return getUserOrGroupListWithoutSearch("group");
  }, [groupList, unreadChatMessage]);

  const searchedGroupList = useMemo(() => {
    const list = searchAttendeeData
      ? groupListingData.filter((val) =>
          val.groupName
            ?.toLowerCase()
            ?.match(searchAttendeeData?.toLowerCase()),
        )
      : groupListingData;
    return list;
  }, [searchAttendeeData]);

  useEffect(async () => {
    sendNotificationHandler(notificationData);
  }, [notificationData]);

  const finalGroupList = searchAttendeeData
    ? searchedGroupList
    : groupListingData;

  return (
    <>
      <div className="message-list-head d-flex content--h-space-between align-items-center pt-0">
        <div className="d-flex align-items-center gap--4">
          <a
            onClick={() => setIsExpand(!isExpand)}
            className={`icon_container expend-chevron ${
              !isExpand ? "active" : ""
            }`}
          >
            {sessionThemeData ? (
              isExpand ? (
                <svg width="9.13" height="9.13" viewBox="5 9 14 8">
                  <path fill="#b8c0c9" d="m11.998 17 7-8h-14z"></path>
                </svg>
              ) : (
                <svg width="9.13" height="9.13" viewBox="5 9 14 8">
                  <path fill="#b8c0c9" d="m11.998 17 7-8h-14z"></path>
                </svg>
              )
            ) : isExpand ? (
              <svg width="9.13" height="9.13" viewBox="5 9 14 8">
                <path fill="#b8c0c9" d="m11.998 17 7-8h-14z"></path>
              </svg>
            ) : (
              <svg width="9.13" height="9.13" viewBox="5 9 14 8">
                <path fill="#b8c0c9" d="m11.998 17 7-8h-14z"></path>
              </svg>
            )}
          </a>
          <span className="text-green">
            {globalStrings[selectedLanguage]?.$groupMessage}
          </span>
        </div>

        {showGroup ? (
          <a
            onClick={() => {
              setChooseGroupTypeShow(true);
              setShow(true);
            }}
            className="icon_container"
          >
            {sessionThemeData ? (
              <svg width="9.13" height="9.13" viewBox="0 0 9.13 9.13">
                <path
                  fill="#b8c0c9"
                  id="Icon_awesome-plus"
                  data-name="Icon awesome-plus"
                  d="M7.621,5.051H4.329V1.759A.509.509,0,0,0,3.82,1.25H3.311a.509.509,0,0,0-.509.509V5.051H-.491A.509.509,0,0,0-1,5.561V6.07a.509.509,0,0,0,.509.509H2.8V9.871a.509.509,0,0,0,.509.509H3.82a.509.509,0,0,0,.509-.509V6.579H7.621A.509.509,0,0,0,8.13,6.07V5.561A.509.509,0,0,0,7.621,5.051Z"
                  transform="translate(1 -1.25)"
                />
              </svg>
            ) : (
              <svg width="9.13" height="9.13" viewBox="0 0 9.13 9.13">
                <path
                  fill="#b8c0c9"
                  id="Icon_awesome-plus"
                  data-name="Icon awesome-plus"
                  d="M7.621,5.051H4.329V1.759A.509.509,0,0,0,3.82,1.25H3.311a.509.509,0,0,0-.509.509V5.051H-.491A.509.509,0,0,0-1,5.561V6.07a.509.509,0,0,0,.509.509H2.8V9.871a.509.509,0,0,0,.509.509H3.82a.509.509,0,0,0,.509-.509V6.579H7.621A.509.509,0,0,0,8.13,6.07V5.561A.509.509,0,0,0,7.621,5.051Z"
                  transform="translate(1 -1.25)"
                />
              </svg>
            )}
          </a>
        ) : (
          ""
        )}
      </div>
      {loader == true ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div className="loader_spinner__box__sm">
            <div className="spin"></div>
          </div>
        </div>
      ) : (
        <>
          {
            <div className="group-message">
              {isExpand &&
                finalGroupList
                  .filter((val) => val.attendeeId.includes(senderID))
                  .map((group) => (
                    <Group
                      key={group.groupId}
                      group={group}
                      sidebarActiveData={sidebarActiveData}
                      setLoaderActiveData={setLoaderActiveData}
                      callDetail={callDetail}
                      allAttendeeList={allAttendeeList}
                      groupIndex={group.groupId}
                      groupTypingData={groupTypingData}
                      loaderActiveData={loaderActiveData}
                      setCallRoom={setCallRoom}
                      unreadChatMessage={unreadChatMessage}
                      dispatch={dispatch}
                      sessionThemeData={sessionThemeData}
                      selectedLanguage={selectedLanguage}
                      authSession={authSession}
                      callIconLoader={callIconLoader}
                      setCallIconLoader={setCallIconLoader}
                      authSessionUser={authSessionUser}
                      monitorActiveLoader={monitorActiveLoader}
                      callActiveLoader={callActiveLoader}
                    />
                  ))
                  .filter((key) => {
                    if (searchAttendeeData) {
                      return key.props.group.groupName
                        ?.toLowerCase()
                        ?.match(searchAttendeeData?.toLowerCase());
                    } else {
                      return key;
                    }
                  })}
              {show && (
                <GroupAdd
                  show={show}
                  chooseGroupTypeShow={chooseGroupTypeShow}
                  setChooseGroupTypeShow={setChooseGroupTypeShow}
                  handleClose={() => {
                    setChooseGroupTypeShow(false);
                    setShow(false);
                  }}
                  attendeeList={attendeeList}
                />
              )}

              {inBoundCallPopUpShow && callDetail?.type === "group" ? (
                <InBoundCall room={room} selectedLanguage={selectedLanguage} />
              ) : null}

              {outBoundCallPopUpShow &&
                callDetail?.senderID === senderID &&
                callDetail?.type === "group" && (
                  <OutBoundCall
                    room={room}
                    selectedLanguage={selectedLanguage}
                  />
                )}
            </div>
          }
        </>
      )}
    </>
  );
};

export default memo(SidebarGroupMessage);

const Group = ({
  group,
  sidebarActiveData,
  callDetail,
  allAttendeeList,
  active,
  groupIndex,
  groupTypingData,
  loaderActiveData,
  unreadChatMessage,
  sessionThemeData,
  selectedLanguage,
  authSession,
  callIconLoader,
  setCallIconLoader,
  authSessionUser,
  monitorActiveLoader,
  callActiveLoader,
}) => {
  const { user_unique_key: senderID } = getAuthSession();
  const [activeGroup, setActiveGroup] = useState("");
  const [userOnCall, setUserOnCall] = useState(false);
  const onCall =
    allAttendeeList?.find((v) => v?.user_id === senderID)?.onCall ?? false;
  const isTypingGroupSenderId =
    groupTypingData?.find(
      (obj) =>
        obj?.receiverID === group?.groupId &&
        obj?.senderID !== senderID &&
        obj.event_type === "typing-start",
    )?.senderID ?? "";

  const isTypingName =
    isTypingGroupSenderId != ""
      ? allAttendeeList?.find((v) => v?.user_id === isTypingGroupSenderId)
          ?.name ?? false
      : false;
  const unreadMessageCount =
    unreadChatMessage.find(
      (filterItem) => filterItem.user_id === group?.groupId,
    )?.count ?? 0;

  useEffect(() => {
    if (callDetail) {
      const { type, room, calling_status } = callDetail;
      if (
        type === "group" &&
        calling_status !== "decline" &&
        calling_status !== "ended"
      ) {
        setActiveGroup(room);
      } else {
        setActiveGroup("");
      }
    }
  }, [callDetail]);

  const busyPopUpHandler = () => {
    setUserOnCall(true);
  };

  return (
    <>
      <div
        className={`head align-items-center ${
          sidebarActiveData === group.groupId ? "selected" : ""
        }`}
        onClick={() => {
          !loaderActiveData && authSession?.chat === true
            ? openUserChat(group, true)
            : "";
        }}
      >
        <div className="user align-items-start">
          <div
            className={`avatar ${
              isTypingName !== false ? "person-typing" : ""
            }`}
          >
            {isTypingName !== false ? (
              <div className="typing">
                <svg
                  id="typing_bubble"
                  data-name="typing bubble"
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="66"
                  viewBox="0 0 120 66"
                >
                  <g>
                    <circle className="typing-dot" cx="46" cy="30" r="8" />
                    <circle className="typing-dot" cx="68" cy="30" r="8" />
                    <circle className="typing-dot" cx="90" cy="30" r="8" />
                  </g>
                </svg>
              </div>
            ) : (
              <>
                {sessionThemeData ? (
                  <img src="./assets/images/group-dark.png" alt="Group" />
                ) : (
                  <img src="./assets/images/group-light.png" alt="Group" />
                )}
                <span className="status status-group count-badge ">
                  {group?.attendeeId?.length}
                </span>
              </>
            )}
          </div>
          <div className="d-flex flex-column contact_info">
            <div
              className={`name unread-msg-name ${
                unreadMessageCount > 0 ? "text-bold" : ""
              }`}
            >
              {group.groupName}
            </div>
            <small
              className={`text-small ${
                unreadMessageCount > 0 ? "text-bold" : ""
              }`}
            >
              {group.description}
            </small>
          </div>
        </div>

        <span className="d-flex align-items-center">
          {unreadMessageCount > 0 && (
            <span className="chat_count d-flex align-items-center justify-content-center align-self-center mr-2">
              {unreadMessageCount > 9 ? "9+" : unreadMessageCount}
            </span>
          )}

          {authSession?.video === true ? (
            <>
              {callIconLoader === group.groupId ? (
                <>
                  <div className="w-100 d-flex align-items-center justify-content-center icon_container">
                    <div className="loader_spinner__box__xs">
                      <div className="spin"></div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <a
                    className={`icon d-flex align-items-center icon_container ${
                      (group?.attendeeId?.length <= 69 &&
                        activeGroup === group?.groupId) ||
                      group?.groupCall
                        ? "text-green"
                        : ""
                    } ${
                      authSessionUser?.onCall ||
                      authSessionUser?.monitor_status ||
                      monitorActiveLoader != "" ||
                      callActiveLoader != ""
                        ? "btn-disabled"
                        : ""
                    } `}
                    href={void 0}
                    onClick={(e) => {
                      {
                        group?.attendeeId?.length <= 69
                          ? (onCall || active
                              ? busyPopUpHandler()
                              : GroupOutBoundCall(
                                  group,
                                  false,
                                  setCallIconLoader,
                                ),
                            e.stopPropagation())
                          : e.preventDefault();
                      }
                    }}
                  >
                    {group?.attendeeId?.length <= 69 ? (
                      activeGroup === group?.groupId || group?.groupCall ? (
                        <div className="tooltip">
                          <a
                            className="d-flex"
                            data-tip
                            data-for={`registerTip${groupIndex}`}
                          >
                            <svg
                              width="15.826"
                              height="12.867"
                              viewBox="0 0 15.826 11.867"
                            >
                              <g
                                id="Group_2303"
                                data-name="Group 2303"
                                transform="translate(-7469.257 -3666.066)"
                              >
                                <path
                                  fill="#b8c0c9"
                                  id="video-camera"
                                  d="M15.1,129.938a1.31,1.31,0,0,0-1.379.121l-1.208.9a3.3,3.3,0,0,0-3.28-2.967H3.3a3.3,3.3,0,0,0-3.3,3.3v5.274a3.3,3.3,0,0,0,3.3,3.3H9.23a3.3,3.3,0,0,0,3.28-2.967l1.206.9a1.318,1.318,0,0,0,2.11-1.055v-5.618A1.311,1.311,0,0,0,15.1,129.938Zm-3.89,6.622a1.978,1.978,0,0,1-1.978,1.978H3.3a1.978,1.978,0,0,1-1.978-1.978v-5.274A1.978,1.978,0,0,1,3.3,129.308H9.23a1.978,1.978,0,0,1,1.978,1.978Zm3.3.169-1.978-1.474v-2.665l1.978-1.474Z"
                                  transform="translate(7469.257 3538.077)"
                                />
                                <rect
                                  fill="#2bc18d"
                                  id="Rectangle_698"
                                  data-name="Rectangle 698"
                                  width="7"
                                  height="6"
                                  rx="1.5"
                                  transform="translate(7472 3669)"
                                />
                              </g>
                            </svg>
                            <ReactTooltip
                              id={`registerTip${groupIndex}`}
                              place="top"
                              effect="solid"
                            >
                              {globalStrings[selectedLanguage]?.$joinCall}
                            </ReactTooltip>
                          </a>
                        </div>
                      ) : (
                        <div className="tooltip">
                          <a
                            className="d-flex"
                            data-tip
                            data-for={`registerTip${groupIndex}`}
                          >
                            <svg
                              width="15.826"
                              height="12.867"
                              viewBox="0 0 15.826 11.867"
                            >
                              <path
                                fill="#b8c0c9"
                                id="video-camera"
                                d="M15.1,129.938a1.31,1.31,0,0,0-1.379.121l-1.208.9a3.3,3.3,0,0,0-3.28-2.967H3.3a3.3,3.3,0,0,0-3.3,3.3v5.274a3.3,3.3,0,0,0,3.3,3.3H9.23a3.3,3.3,0,0,0,3.28-2.967l1.206.9a1.318,1.318,0,0,0,2.11-1.055v-5.618A1.311,1.311,0,0,0,15.1,129.938Zm-3.89,6.622a1.978,1.978,0,0,1-1.978,1.978H3.3a1.978,1.978,0,0,1-1.978-1.978v-5.274A1.978,1.978,0,0,1,3.3,129.308H9.23a1.978,1.978,0,0,1,1.978,1.978Zm3.3.169-1.978-1.474v-2.665l1.978-1.474Z"
                                transform="translate(0 -127.99)"
                              />
                            </svg>
                          </a>
                          <ReactTooltip
                            id={`registerTip${groupIndex}`}
                            place="top"
                            effect="solid"
                          >
                            {globalStrings[selectedLanguage]?.$videoCall}{" "}
                            {group.groupName}
                          </ReactTooltip>
                        </div>
                      )
                    ) : (
                      <div className="tooltip">
                        <a
                          className="d-flex"
                          data-tip
                          data-for={`registerTip${groupIndex}`}
                        >
                          <svg
                            width="16.826"
                            height="16.867"
                            viewBox="2 2.29 20 19.41"
                          >
                            <path
                              fill="#b8c0c9"
                              d="M18 7c0-1.103-.897-2-2-2H6.414L3.707 2.293 2.293 3.707l18 18 1.414-1.414L18 16.586v-2.919L22 17V7l-4 3.333V7zm-2 7.586L8.414 7H16v7.586zM4 19h10.879l-2-2H4V8.121L2.145 6.265A1.977 1.977 0 0 0 2 7v10c0 1.103.897 2 2 2z"
                            ></path>
                          </svg>
                        </a>
                        <ReactTooltip
                          id={`registerTip${groupIndex}`}
                          place="top"
                          effect="solid"
                        >
                          {globalStrings[selectedLanguage]?.$GroupCallMaxLimit}
                        </ReactTooltip>
                      </div>
                    )}
                  </a>
                </>
              )}
            </>
          ) : null}
        </span>
      </div>
      <UserOnCall
        userOnCall={userOnCall}
        handleClose={setUserOnCall}
        name={"user"}
        onCall={onCall || active}
        selectedLanguage={selectedLanguage}
      />
    </>
  );
};
